import { PrinterIcon } from '@heroicons/react/24/outline'

function Footer() {
  return (
    <footer style={{backgroundColor: '#4d4d4d', color: '#F8E04B'}} className="rounded-b-lg">
      <div className="w-full flex items-center justify-between">
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">main</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">appts</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">scans</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">issues</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">meds</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">vitals</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">in-house<br/>labs</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">orders</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">documents</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">comms</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">financial</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100">tickles</button>
        <button className="px-4 py-2 hover:shadow-md hover:border hover:border-yellow-100 ml-12"><PrinterIcon className='h-5 w-5'/></button>
      </div>
    </footer>
  );
}



  export default Footer;

