import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useAxios from "../utils/useAxios";
import Spinner from "../components/Spinner";
// import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

export default function UpdateExamTypeInfo(props) {
  const piForm = useRef();
  const [examTypeInfo, setExamTypeInfo] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  useEffect(() => {
    if (props.selectedExamType != null) {
      setExamTypeInfo({ ...props.selectedExamType });
    } else {
      initExamTypeInfo();
    }
  }, [props]);
  const initExamTypeInfo = () => {
    setExamTypeInfo({
      id: null,
      type_name: "",
      description: "",
    });
    setError({});
  };

  const submitForm = () => {
    piForm.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (examTypeInfo.type_name === "") {
      setError({ type_name: !examTypeInfo.type_name });
    } else {
      setLoading(true);
      try {
        const res = await axios.post("/examtypes/upsert", {
          ...examTypeInfo,
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      setError({});
      props.onClose();
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.onClose}>
        <Spinner show={loading} />
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    ref={piForm}
                    action="#"
                    method="POST"
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    onSubmit={onSubmit}
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gradient-to-r from-indigo-600 to-blue-500 px-4 py-6 sm:px-6 rounded-tl-lg">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              {props.selectedExamType !== null
                                ? "Edit Exam Type"
                                : "Create Exam Type"}
                            </Dialog.Title>
                          </div>
                        </div>
                      </div>
                      {examTypeInfo != null && (
                        <>
                          {/* Divider container */}
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                            {/* Type Name */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="typename"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Type Name
                                  <span className="text-red-700"> *</span>
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="typename"
                                  id="typename"
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 ${
                                    error.type_name ? "ring-red-500" : ""
                                  }`}
                                  value={examTypeInfo?.type_name || ""}
                                  onChange={(e) =>
                                    setExamTypeInfo({
                                      ...examTypeInfo,
                                      type_name: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* Description */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="desciption"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Description
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="description"
                                  id="description"
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                                  value={examTypeInfo?.description || ""}
                                  onChange={(e) =>
                                    setExamTypeInfo({
                                      ...examTypeInfo,
                                      description: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => props.onClose()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={submitForm}
                        >
                          {examTypeInfo && examTypeInfo.id == null
                            ? "Create"
                            : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
