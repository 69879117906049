export const convertDateString = (inputDate, age_display=true) => {
  let dateObj = new Date(inputDate);

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let month = months[dateObj.getMonth()];
  let day = dateObj.getDate();
  let year = dateObj.getFullYear();

  let formattedDate = month + " " + day + ", " + year;

  const today = new Date();
  const dob = new Date(inputDate);

  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  // If the birth month is ahead of the current month OR
  // if the birth month is the same as the current month but the birth day is ahead of the current day
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  return formattedDate + (age_display ? " - " + age + " yrs" : "")
}
