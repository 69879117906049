import React, { useEffect, useState, useRef, Suspense } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
// import { useSpring, animated } from "react-spring";
import {
  Box,
  Sphere,
  Line,
  Decal,
  RenderTexture,
  useTexture,
  useGLTF,
  PerspectiveCamera,
  Text,
  Circle,
  PivotControls,
  Html,
  Billboard,
  TrackballControls,
} from '@react-three/drei';
import { Vector3, Box3 } from 'three';
import * as THREE from 'three';
import NoteDisplay from './NoteDisplay';
import { InputNote } from './InputNote';
import Spinner from './Spinner';
export default function Model({ ...props }) {
  const textRef = useRef();
  const group = useRef();
  const lineRef = useRef();
  const boxRef = useRef();
  const sphereRef = useRef();
  const circleRef = useRef();
  const decalRef = useRef();
  const [restored, setRestored] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [linePositions, setLinePositions] = useState([
    [0, 0, 0],
    [0.02, 0.02, 0.02],
  ]);
  const [matchingPoint, setMatchingPoint] = useState([]);

  // const [moving, setMoving] = useState(false);
  let moving = false;
  const [decalPosition, setDecalPosition] = useState([
    -0.038387734448849464, 1.1447500392776212, 0.11821357869561266,
  ]);

  const [clickedPainPoint, setClickedPainPoint] = useState(null);
  const [inputNotes, setInputNotes] = useState('');
  const [transparency, setTransparency] = useState(0.7);
  // const { nodes, materials } = useGLTF(
  //   "https://d1a370nemizbjq.cloudfront.net/f8e25565-9091-474b-aed9-67625f51855a.glb"
  // );
  // const { nodes, materials } = useGLTF(
  // `${process.env.REACT_APP_API_BASE_URL}media/avatars/${
  //   props.profile && props.profile.sex == 0 ? "male" : "female"
  // }/Model.glb`
  // );

  const genders = ['male', 'female'];
  const gender =
    (props.profile && props.profile.sex && genders[props.profile.sex]) ||
    'male';
  const normal = useGLTF(
    `${process.env.REACT_APP_API_BASE_URL}media/avatars/${gender}/model.glb`
  );
  const blue = useGLTF(
    props &&
      props.modelType &&
      `${process.env.REACT_APP_API_BASE_URL}${props.modelType.model_url}`
  );
  // const initialRotationDifference = blue.scene.rotation.clone().sub(normal.scene.rotation);
  // const initialRotationDifference = normal.scene.rotation.sub(blue.scene.rotation);
  // const boundingBoxNormal = normal.nodes.Node.geometry.boundingBox;
  // const boundingBoxBlue = blue.nodes.HG_Body.geometry.boundingBox;

  // // Translation calculation
  // const translationX = boundingBoxNormal.max.x - boundingBoxBlue.max.x;
  // const translationY = boundingBoxNormal.max.y - boundingBoxBlue.max.y;
  // const translationZ = boundingBoxNormal.max.z - boundingBoxBlue.max.z;

  // // Rotation calculation
  // const rotationX = Math.atan2(translationY, translationZ);
  // const rotationY = Math.atan2(translationX, translationZ);
  // const rotationZ = Math.atan2(translationX, translationY);
  const rotationBlue = blue.scene.children[0].rotation;
  const rotationNormal = normal.scene.children[0].rotation;
  // Assuming normal.nodes.Node.geometry is your geometry
  // const geometry = blue.scene.children[0].geometry.clone(); // Clone the geometry to avoid modifying the original

  // Create a matrix to apply the rotation
  const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(
    new THREE.Euler(
      rotationBlue.x - rotationNormal.x,
      rotationBlue.y - rotationNormal.y,
      rotationBlue.z - rotationNormal.z
    )
  );

  let rotatedMesh = [];
  // Apply the rotation to the geometry vertices
  // geometry.applyMatrix4(rotationMatrix);
  blue.scene.children.map((child, index) => {
    if (child.geometry) {
      let geometry = child.geometry.clone();
      geometry.applyMatrix4(rotationMatrix);
      rotatedMesh.push(new THREE.Mesh(geometry, child.material));
    } else {
      child?.children?.forEach((c) => {
        let geometry = c.geometry.clone();
        geometry.applyMatrix4(rotationMatrix);
        rotatedMesh.push(new THREE.Mesh(geometry, c.material));
      });
    }
  });
  // Create a new mesh with the rotated geometry
  // const rotatedMesh = new THREE.Mesh(geometry, blue.scene.children[0].material);

  // Load textures here and store them in state
  // const [texture, setTexture] = useState(useTexture("/red.png"));
  const textures = [
    useTexture('/sky.png'),
    useTexture('/green.png'),
    useTexture('/yellow.png'),
    useTexture('/red.png'),
    useTexture('/lilac.png'),
  ];
  // useEffect(() => {
  //   setTexture(textures[props.selectedLevel]);
  // }, [props.selectedLevel]);
  const setDisplayNoteTransparency = (transp) => {
    setTransparency(transp);
  };

  // const { nodes, materials } = useGLTF(
  //   "http://localhost:8000/media/avatars/f70in1500lbs.glb"
  // );
  // const { nodes: starbucks_nodes, materials: starbucks_materials } = useGLTF(
  //   "/coffee-transformed.glb"
  // );
  // const obj = useLoader(OBJLoader, "http://164.92.96.85:8000/media/avatars/Female70in150lbs.obj")

  const onPointerMove = (event) => {
    moving = true;
    if (event.isPrimary) {
      const p = event.point.clone();
      p.add(new Vector3(-0.025, 0.9, 0)); //based on model poistion
      setDecalPosition([p.x, p.y, p.z]);
      const matchingPoint = isGeneratedPoint(p);
      if (matchingPoint) {
        setMatchingPoint(matchingPoint);
        // This is a generated point, handle accordingly
      } else {
        setMatchingPoint([]);
      }
    }
  };

  const onPointerOver = (e) => {
    setIsHovered(true);
  };

  const onPointerOut = (e) => {
    setIsHovered(false);
  };

  const onPointerDown = (e) => {
    moving = false;
  };

  const onPointerUp = (e) => {};

  const isGeneratedPoint = (p) => {
    const proximityThreshold = 0.005;
    const matchingPoint = props.decalPoints.find((point) => {
      if (point.deleted !== true) {
        const pointPosition = new Vector3().fromArray(point.position);
        const distance = p.distanceTo(pointPosition);
        return distance < proximityThreshold;
      }
    });
    return matchingPoint;
  };
  const onClick = (e) => {
    const p = e.point.clone();
    p.add(new Vector3(-0.025, 0.9, 0)); //based on model poistion
    const matchingPoint = isGeneratedPoint(p);
    if (matchingPoint) {
      // This is a generated point, handle accordingly
      handlePointClick(matchingPoint);
    } else {
      setClickedPainPoint(null);
      // This is not a generated point, handle accordingly
    }
  };

  useEffect(() => {
    let prevPoints = [...props.previousPoints],
      index = props.actionIndex;

    if (!restored) {
      prevPoints = prevPoints.slice(0, index + 1);
      prevPoints.push(props.decalPoints);
      props.setPreviousPoints(prevPoints);
      props.setActionIndex(index + 1);
      setRestored(true);
    } else {
      if (index === 0 && props.decalPoints && prevPoints.length === 1) {
        prevPoints = prevPoints.slice(0, index);
        prevPoints.push(props.decalPoints);
        props.setPreviousPoints(prevPoints);
      }
    }
  }, [props.decalPoints]);

  const onDoubleClick = (e) => {
    e.stopPropagation();
    const p = e.point.clone();
    p.add(new Vector3(-0.025, 0.9, 0)); //based on model poistion
    const matchingPoint = isGeneratedPoint(p);
    if (props.selectedPose === 5) {
      alert('Please select pose');
    } else {
      if (matchingPoint) {
        console.log('That point has already been clicked');
      } else {
        let _decalPoints = [...props.decalPoints];

        const newPainPoint = {
          position: [p.x, p.y, p.z],
          pain_level: props.selectedLevel,
          pose_id: props.selectedPose,
          size: props.selectedSize,
          notes: '',
        };

        props.setDecalPoints([..._decalPoints, newPainPoint]);
        setRestored(false);
      }
    }
  };
  const handlePointClick = (painPoint) => {
    setClickedPainPoint(painPoint);
    setInputNotes(painPoint.notes);
  };
  const onUpdate = (e) => {};
  const handleSaveNotes = (selectedColor, selectedSize) => {
    // Find the index of the clicked pain point in decalPoints
    const index = props.decalPoints.findIndex(
      (point) => point.position === clickedPainPoint.position
    );

    if (index !== -1) {
      // Create a copy of decalPoints to avoid mutating the state directly
      const updatedDecalPoints = [...props.decalPoints];
      // Update the notes of the clicked pain point
      updatedDecalPoints[index] = {
        ...updatedDecalPoints[index],
        notes: inputNotes,
        pain_level: selectedColor,
        size: selectedSize,
      };

      props.setDecalPoints(updatedDecalPoints);
      setRestored(false);
    }

    // Close the modal or reset inputNotes if needed
    setClickedPainPoint(null);
  };

  const handleDeletePoint = (point) => {
    // Find the index of the point to be deleted in decalPoints
    const index = props.decalPoints.findIndex(
      (p) => p.position === clickedPainPoint.position
    );

    if (index !== -1) {
      // Create a copy of decalPoints to avoid mutating the state directly
      // const updatedDecalPoints = [...props.decalPoints];
      // Remove the point from the copied array
      // updatedDecalPoints.splice(index, 1);

      const updatedDecalPoints =
        props.decalPoints &&
        props.decalPoints.map((obj, i) => {
          if (i === index) {
            return { ...obj, deleted: true }; // Placeholder for the deleted element
          }
          return obj;
        });
      props.setDecalPoints(updatedDecalPoints);
      setRestored(false);
    }
    setClickedPainPoint(null);
  };
  const [enableCamera, setEnableCamera] = useState(true);
  // Function to update enableCamera state
  const updateEnableCamera = (value) => {
    setEnableCamera(value);
  };
  useEffect(() => {
    props.updateEnableControls(enableCamera);
  }, [enableCamera]);

  return (
    <group
      ref={group}
      {...props}
      // rotation={[rotationX, rotationY, rotationZ]}
      // scale={[1, 1, 1]}
    >
      {/* Render the Html component for the modal within the canvas */}
      {clickedPainPoint && (
        <Suspense fallback={null}>
          <mesh
            position={clickedPainPoint.position}
            onClick={(e) => e.stopPropagation()}
          >
            <Html position={[0, 0, 0]}>
              <InputNote
                clickedPoint={clickedPainPoint}
                onChange={(e) => setInputNotes(e.target.value)}
                value={inputNotes}
                setClickedPainPoint={setClickedPainPoint}
                handleSaveNotes={handleSaveNotes}
                enableCamera={enableCamera}
                updateEnableCamera={updateEnableCamera}
                handleDeletePoint={handleDeletePoint}
              />
            </Html>
          </mesh>
        </Suspense>
      )}
      {/* <primitive object={blue.scene} /> */}
      {!(
        rotationBlue.x === 0 &&
        rotationBlue.y === 0 &&
        rotationBlue.z === 0
      ) &&
        rotatedMesh &&
        rotatedMesh.map((mesh, index) => (
          <mesh
            key={index}
            castShadow
            geometry={mesh.geometry}
            material={mesh.material}
            onClick={onClick}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            onPointerDown={onPointerDown}
            onDoubleClick={onDoubleClick}
            onUpdate={onUpdate}
          >
            <Decal
              position={decalPosition}
              rotation={[0, 0, 0]}
              scale={props.selectedSize * 0.02}
              visible={isHovered}
            >
              <meshStandardMaterial
                roughness={0.6}
                transparent
                polygonOffset
                polygonOffsetFactor={-10}
              >
                <RenderTexture attach='map' anisotropy={16}>
                  <PerspectiveCamera
                    makeDefault
                    manual
                    aspect={1 / 1}
                    position={[0, 0, 5]}
                  />
                  <ambientLight intensity={0.5} />
                  <directionalLight position={[10, 10, 5]} />
                  <Circle>
                    <meshStandardMaterial color='yellow' />
                  </Circle>
                </RenderTexture>
              </meshStandardMaterial>
            </Decal>
            {/* {
                  props.decalPoints.length && props.decalPoints.map((decalPoint, index) => {
                    return decalPoint.pose_id == props.selectedPose && (
                      <PainPoint key={`${index}-pp`} position={decalPoint.position} level={decalPoint.pain_level} scale={decalPoint.size * 0.01} />
                    )
                  })
                } */}
            {props.decalPoints.length &&
              props.decalPoints.map((decalPoint, index) => {
                return (
                  decalPoint.deleted !== true &&
                  (props.selectedPose === 5 ||
                    decalPoint.pose_id === props.selectedPose) && (
                    <PainPoint
                      key={`${index}-pp`}
                      position={decalPoint.position}
                      texture={textures[decalPoint.pain_level]}
                      scale={decalPoint.size * 0.01}
                    >
                      {
                        /* Display inputted notes as text */
                        props.showNotes && (
                          <NoteDisplay
                            point={decalPoint}
                            handlePointClick={handlePointClick}
                            setDisplayNoteTransparency={
                              setDisplayNoteTransparency
                            }
                            transparency={
                              matchingPoint.position === decalPoint.position
                                ? 1
                                : transparency
                            }
                          />
                        )
                      }
                    </PainPoint>
                  )
                );
              })}
          </mesh>
        ))}
    </group>
  );
}

const PainPoint = (pp_props) => {
  // let colorMapURL = "/red.png";

  // switch (pp_props.level) {
  //   case 0:
  //     colorMapURL = "/sky.png";
  //     break;

  //   case 1:
  //     colorMapURL = "/green.png";
  //     break;

  //   case 2:
  //     colorMapURL = "/yellow.png";
  //     break;

  //   case 3:
  //     colorMapURL = "/red.png";
  //     break;

  //   case 4:
  //     colorMapURL = "/lilac.png";
  //     break;

  //   default:
  //     break;
  // }

  // const colorMapURL = colorMapURLs[pp_props.level] || "/red.png"; // Default to red.png if level not found
  // const colorMap = useTexture(colorMapURL);

  return <Decal {...pp_props} rotation={[0, 0, 0]} map={pp_props.texture} />;
};

// useGLTF.preload(
//   "http://164.92.96.85:8000/media/avatars/f70in1500lbs.glb"
// );
