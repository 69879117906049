export const colors = [
  {
    value: 0,
    name: "0",
    bgColor: "bg-sky-400",
    selectedColor: "ring-sky-800",
  },
  {
    value: 1,
    name: "1",
    bgColor: "bg-green-400",
    selectedColor: "ring-green-800",
  },
  {
    value: 2,
    name: "2",
    bgColor: "bg-yellow-400",
    selectedColor: "ring-yellow-800",
  },
  {
    value: 3,
    name: "3",
    bgColor: "bg-red-400",
    selectedColor: "ring-red-800",
  },
  {
    value: 4,
    name: "Needle",
    bgColor: "#ff03ea",
    selectedColor: "ring-fuchsia-800",
  },
];

export const sizes = [
  { value: 1, name: "Small" },
  { value: 2, name: "Medium" },
  { value: 3, name: "Large" },
];

export const poses = [
  { value: 1, name: "Supine" },
  { value: 2, name: "Prone" },
  { value: 3, name: "Sitting" },
  { value: 4, name: "Standing" },
  { value: 5, name: "View All"}
];