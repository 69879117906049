import { useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import logo from "../assets/images.png";
import {
  Cog8ToothIcon,
  HomeIcon,
  XCircleIcon,
  UserCircleIcon,
  CalendarIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { convertDateString } from "../utils/ConvertDateString";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



const Header = ({ color = "gray", ...props }) => {
  const colorMap = {
    red: {
      title: "text-red-900",
      info: "text-red-600",
    },
    gray: {
      title: "text-gray-700",
      info: "text-gray-600",
    },
    white: {
      title: "text-gray-200",
      info: "text-gray-100",
    },
    // Add more colors as needed
  };
  return (
    <div>
      <div className="py-2 select-none">
        <h2
          className={`text-3xl font-bold ${colorMap[color].title} mb-2 px-2`}
        >
          {props.profile &&
            `${props.profile.first_name} ${props.profile.last_name}`}
        </h2>
        <div className="flex flex-wrap">
          <div
            className={`${
              colorMap[color].info
            } mr-4 flex space-x-8 items-center`}
          >
            <CalendarIcon className="h-5 w-5 m-1" />
            {/* <span className="font-semibold">Birthday:</span>{" "} */}
            {props.profile && convertDateString(props.profile.birthday)}
          </div>
          <div
            className={`${
              colorMap[color].info
            } mr-4 flex space-x-8 items-center`}
          >
            <UserCircleIcon className="h-5 w-5 m-1" />
            {/* <span className="font-semibold">Gender:</span>{" "} */}
            {props.profile && (props.profile.sex === 0 ? "Male" : "Female")}
          </div>
          <div
            className={`${
              colorMap[color].info
            } mr-4 flex space-x-8 items-center`}
          >
            <GlobeAltIcon className="h-5 w-5 m-1" />
            {/* <span className="font-semibold">Language:</span>{" "} */}
            {props.profile && props.profile.language}
          </div>
        </div>
        {!props.size ||
          (props.size !== "small" && (
            <>
              <div className="mt-6">
                <p className="text-gray-600">
                  <span className="font-semibold">Allergies:</span> NKDA
                </p>
              </div>
              <div className="mt-6">
                <h1 className="text-2xl font-bold text-gray-800">PainScan</h1>
              </div>
            </>
          ))}
      </div>

      <div
        style={{ backgroundColor: "#4d4d4d", color: "#F8E04B" }}
        className="hidden items-center justify-between h-8 px-4"
      >
        <div className=""> </div>
        <div className="flex items-center">
          {!props.size ||
            (props.size != "small" && (
              <>
                <button
                  type="button"
                  className="rounded-full p-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  <Cog8ToothIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="rounded-full p-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  <HomeIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </>
            ))}
          <button
            type="button"
            className="rounded-full p-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            <XCircleIcon
              className="h-5 w-5"
              aria-hidden="true"
              onClick={props.closeAction}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
