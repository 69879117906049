/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, Suspense, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Model from '../components/Model';
import Header from '../components/Header';
import { useParams, useNavigate } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react';
import useAxios from '../utils/useAxios';
import Spinner from '../components/Spinner';
import { confirmAlert } from 'react-confirm-alert';
import { colors, sizes, poses } from '../utils/examConfig';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function Scan() {
  const navigate = useNavigate();
  const axios = useAxios();
  const { patient_id, exam_id } = useParams();
  const [decalPoints, setDecalPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [note, setNote] = useState('');
  const [noteChanged, setNoteChanged] = useState(false);
  const [selectedColor, setSelectedColor] = useState(3);

  const [selectedPose, setSelectedPose] = useState(1);
  const [selectedSize, setSelectedSize] = useState(1);
  const [saveState, setSaveState] = useState(false);
  const [undoState, setUndoState] = useState(false);
  const [redoState, setRedoState] = useState(false);
  const [actionIndex, setActionIndex] = useState(0);
  const [previousPoints, setPreviousPoints] = useState([[]]);
  const [examType, setExamType] = useState(null);
  const [examTypeOptions, setExamTypeOptions] = useState([]);
  const [error, setError] = useState({});
  const [showNotes, setShowNotes] = useState(true);
  const [showTooltip, setShowTooltip] = useState(null);
  const [examDate, setExamDate] = useState({ created: null, updated: null });
  const [modelType, setModelType] = useState(null);
  const [modelTypeChanged, setModelTypeChanged] = useState(false);
  const [modelTypeOptions, setModelTypeOptions] = useState([]);
  const [draftStatus, setDraftStatus] = useState(null);
  const [examTypeChanged, setExamTypeChanged] = useState(false);
  const [enableControls, setEnableControls] = useState(true);
  const textRef = useRef(null);
  const orbitControls = useRef();

  const toggleShowNotes = () => {
    setShowNotes(!showNotes);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const fetchModelTypes = async (sex, size) => {
    try {
      setLoading(true);
      const res = await axios.get(`/models?sex=${sex}&size=${size}`);
      if (res.data) {
        setModelTypeOptions(res.data);
      }
    } catch (error) {
      console.log('error occured while loading model types--------', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchExamDetail = async (session) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/patients/${patient_id}/exams/${session.split('&').slice(1).join('&')}`
      );
      if (!res.data.error) {
        let data = res.data;
        data.exam.exam_type && setExamType(data.exam_type);
        setNote(data.exam.exam_note);
        setProfile(data.profile);
        setModelType(data.model);

        setDraftStatus(data.exam.draft_status);
        setExamDate({ created: data.exam.created, updated: data.exam.updated });
        setDecalPoints([
          ...data?.points?.map((value) => {
            return {
              position: [
                parseFloat(value.x),
                parseFloat(value.y),
                parseFloat(value.z),
              ],
              pain_level: value.pain_level,
              pose_id: value.pose_id,
              size: value.radius,
              notes: value.point_note,
            };
          }),
        ]);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchExamTypes = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/examtypes');
      setExamTypeOptions(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchExams = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/patients/${patient_id}/exams`);
      const profileData = res.data.profile;
      if (profileData) {
        fetchModelTypes(profileData.sex, profileData.size);
        setProfile(res.data.profile);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Effect to check the save state after loading the draft
  useEffect(() => {
    // Check save state after loading the draft
    // localStorage.getItem("examDraft") && setSaveState(true);
  }, [decalPoints]);

  const handleResize = () => {
    setCanvasWidth();
    setCanvasHeight(
      window.innerHeight - 145 - textRef.current.clientHeight - 2 - 24
    );
  };

  useEffect(() => {
    setCanvasWidth(textRef.current.clientWidth);
    setCanvasHeight(
      window.innerHeight - 145 - textRef.current.clientHeight - 2 - 24
    );

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [textRef]);

  useEffect(() => {
    fetchExams();
    fetchExamTypes();
  }, [patient_id]);

  useEffect(() => {
    const existingExamUniqueId = sessionStorage.getItem('examUniqueId') || '';
    if (exam_id === 'scan') {
      !existingExamUniqueId &&
        !existingExamUniqueId.includes('scan') &&
        sessionStorage.setItem('examUniqueId', generateTemporaryId());
    } else {
      sessionStorage.setItem('examUniqueId', `${patient_id}&${exam_id}`);
    }
    // }
    // Continue with your logic based on the unique ID
    const session = sessionStorage.getItem('examUniqueId') || '';
    if (patient_id && exam_id) {
      const draft = loadDraftFromLocal() || [];
      const existingIndex = draft.findIndex((obj) => obj.exam_id === session);
      if (existingIndex === -1 && session) {
        fetchExamDetail(session);
      } else loadExistingDraft();
    }
  }, [patient_id, exam_id]);

  const removeLastObjectWithCriteria = (array, filterField, filterValue) => {
    // Filter the array based on the specified criteria
    const filteredArray = array.filter(
      (obj) => obj[filterField] === filterValue
    );
    // Find the index of the last matching object
    const lastIndex = filteredArray.length - 1;
    // Check if there is a matching object
    if (lastIndex >= 0) {
      // Remove the last matching object from the original array
      const originalIndex = array.indexOf(filteredArray[lastIndex]);
      array.splice(originalIndex, 1);
    }
    return array;
  };

  const generateTemporaryId = () => {
    return `${patient_id}&scan&${uuidv4()}`;
  };

  const fetchData = async () => {
    try {
        const param = {
        exam_id: exam_id.includes('scan') ? null : exam_id,
        exam_type: examType?.id,
        points: decalPoints
          ?.filter((value) => value.deleted !== true)
          ?.map((value) => ({
            x: value.position[0],
            y: value.position[1],
            z: value.position[2],
            pain_level: value.pain_level,
            pain_proxy: 0,
            palp_force: 0,
            point_note: value.notes || '',
            pose_id: value.pose_id,
            size: value.size,
          })),
        note,
        model: modelType?.id,
        created: examDate.created,
        updated: examDate.updated,
        temporary_id: sessionStorage.getItem('examUniqueId'),
        draft_status: 0,
      };
      let draft = loadDraftFromLocal() || [];

      // Find the index of the object with the same exam_id in the draft
      const existingIndex = draft.findIndex(
        (obj) => obj.exam_id === sessionStorage.getItem('examUniqueId')
      );

      // If an object with the same exam_id exists, replace it; otherwise, add the new object
      if (existingIndex !== -1) {
        // Replace the existing object with the new one
        draft[existingIndex] = {
          ...param,
          exam_id: sessionStorage.getItem('examUniqueId'),
        };
      } else {
        // Add the new object to the draft array
        draft.push({
          param,
          exam_id: sessionStorage.getItem('examUniqueId'),
        });
      }

      // Save draft to local storage
      saveDraftToLocal(draft);

      await axios.post(`/patients/${patient_id}/exams/upsert`, param);

      const newDraft = draft.filter(
        (item) => item?.exam_id !== sessionStorage.getItem('examUniqueId')
      );
      saveDraftToLocal(newDraft);
      // setSaveState(true);
      setDraftStatus(0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (actionIndex > 0) {
      setUndoState(true);
    } else {
      setUndoState(false);
    }
    if (actionIndex >= 0 && actionIndex + 1 < previousPoints.length) {
      setRedoState(true);
    } else {
      setRedoState(false);
    }

    let draft = loadDraftFromLocal() || [];
    if (actionIndex > 0 && modelType) {
      fetchData();
    } else {
      if ((draftStatus === 0 || sessionStorage.getItem('examUniqueId')) && modelType ) {
        fetchData();
        // setSaveState(true);
      } else !draft.length && setSaveState(false);
    }
  }, [actionIndex]);

  useEffect(() => {
    if (draftStatus === 1 || draftStatus === null) {
      setSaveState(false);
    } else {
      setSaveState(true);
    }
  }, [draftStatus]);

  useEffect(() => {
    if (modelTypeOptions) {
      if (!modelType) {
        // Set initial modelType based on profile
        setModelType(
          modelTypeOptions.find((model) => model.view_label === 'Graticule')
        );
      }
    }
  }, [modelTypeOptions]);

  useEffect(() => {
    // if (examType === null && examTypeOptions) setExamType(examTypeOptions[0]);
    if (examTypeChanged && modelType) {
      fetchData();
    }
    if (
      examType &&
      examType.type_name === 'Fibromyalgia' &&
      modelTypeOptions &&
      examTypeChanged
    ) {
      setModelType(
        modelTypeOptions.find((model) => model.view_label === 'Fibromyalgia')
      );
    }
  }, [examType]);

  useEffect(() => {
    if (examTypeOptions) {
      if (!examType) {
        // Set initial modelType based on profile
        setExamType(examTypeOptions[0]);
      }
    }
  }, [examTypeOptions]);

  useEffect(() => {
    if (modelTypeChanged && modelType) {
      fetchData();
    }
  }, [modelType]);

  useEffect(() => {
    if (noteChanged && modelType) {
      fetchData();
    }
  }, [note]);

  // useEffect(() => {
  //   // if (examType > 0 && actionIndex > 0) fetchData();
  //   if (examTypeOptions) {
  //     const found = examTypeOptions.findIndex(
  //       (examTypeOption) =>
  //         examTypeOption.id === parseInt(examType) &&
  //         examTypeOption.type_name === "Fibromyalgia"
  //     );
  //     if (profile) {
  //       const gender = profile.sex === 0 ? "male" : "female";
  //       // fetchData();
  //       if (found !== -1) {
  //         setModelType(
  //           modelTypeOptions.find(
  //             (model) => model.view_label === "Fibromyalgia"
  //           )
  //         );
  //       } else {
  //         setModelType(
  //           modelTypeOptions.find((model) => model.view_label === "Standard")
  //           // `${process.env.REACT_APP_API_BASE_URL}media/avatars/${gender}/Pose.glb`
  //         );
  //       }
  //     } else {
  //       !modelType &&
  //         setModelType(
  //           `${process.env.REACT_APP_API_BASE_URL}media/avatars/male/Pose.glb`
  //         );
  //     }
  //   }
  // }, [examType, examTypeOptions, profile]);

  const undoPoints = () => {
    // let new_arr = removeLastObjectWithCriteria(
    //   [...decalPoints],
    //   "pose_id",
    //   selectedPose
    // );
    let newPoints;
    if (actionIndex > 0) {
      newPoints = previousPoints[actionIndex - 1];
      setDecalPoints(newPoints);
      setActionIndex(actionIndex - 1);
    } else {
      alert('cannot undo anymore');
    }
  };

  const redoPoints = () => {
    let newPoints;
    // const index = previousPoints.indexOf(decalPoints);
    if (actionIndex >= 0 && actionIndex + 1 < previousPoints.length) {
      newPoints = previousPoints[actionIndex + 1];
      setDecalPoints(newPoints);
      setActionIndex(actionIndex + 1);
    } else {
      alert('cannot redo anymore ');
    }

    // setDecalPoints(previousPoints[previousPoints.length - 1]);
    // setPreviousPoints(previousPoints.pop());
  };

  const saveExam = async () => {
    try {
      setLoading(true);

      if (examType !== 0) {
        let param = {
          exam_id:
            exam_id === 'scan'
              ? sessionStorage.getItem('examUniqueId') || null
              : exam_id,
          exam_type: examType.id,
          points: decalPoints
            ?.filter((value) => value.deleted !== true)
            ?.map((value) => ({
              x: value.position[0],
              y: value.position[1],
              z: value.position[2],
              pain_level: value.pain_level,
              pain_proxy: 0,
              palp_force: 0,
              point_note: value.notes || '',
              pose_id: value.pose_id,
              size: value.size,
            })),
          note,
          temporary_id: null,
          model: modelType.id,
          draft_status: 1,
        };
        let draft = loadDraftFromLocal() || [];
        const existingIndex = draft.findIndex(
          (obj) => obj.exam_id === sessionStorage.getItem('examUniqueId')
        );
        // If an object with the same exam_id exists, replace it; otherwise, add the new object
        if (existingIndex !== -1) {
          // Replace the existing object with the new one
          draft[existingIndex] = {
            ...param,
            exam_id: sessionStorage.getItem('examUniqueId'),
          };
        } else {
          // Add the new object to the draft array
          draft.push({
            param,
            exam_id: sessionStorage.getItem('examUniqueId'),
          });
        }
        saveDraftToLocal(draft);
        await axios.post(`/patients/${patient_id}/exams/upsert`, param);

        const newDraft = draft.filter(
          (item) => item.exam_id !== sessionStorage.getItem('examUniqueId')
        );
        saveDraftToLocal(newDraft);

        // Clear draft from local storage after successfully saving to the backend
        // clearDraftFromLocal();

        navigate(`/patients/${patient_id}/exams`);
      } else {
        setLoading(false);
        setError({
          examType: !examType,
        });
        alert('You have to select an exam type.');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Function to save draft to local storage
  const saveDraftToLocal = (draft) => {
    localStorage.setItem('examDraft', JSON.stringify(draft));
  };

  // Function to clear draft from local storage
  const clearDraftFromLocal = () => {
    localStorage.removeItem('examDraft');
  };

  // Function to load draft from local storage
  const loadDraftFromLocal = () => {
    const draft = localStorage.getItem('examDraft');
    return draft ? JSON.parse(draft) : null;
  };

  // Call this function when the component mounts to load any existing draft
  const loadExistingDraft = () => {
    const draft = loadDraftFromLocal() || [];
    let result = draft.find(
      (item) => item.exam_id === sessionStorage.getItem('examUniqueId')
    );
    if (result) {
      result.exam_type && setExamType(result.exam_type);
      setNote(result.note);
      setExamDate({ created: result.created, updated: result.updated });

      if (result?.points?.length) {
        setDecalPoints([
          ...result?.points?.map((value) => ({
            position: [value.x, value.y, value.z],
            pain_level: value.pain_level,
            pose_id: value.pose_id,
            size: value.size,
            notes: value.point_note,
          }))
        ]);
      }
    }
  };

  const closeAction = () => {
    navigate(`/patients/${patient_id}/exams`);
  };

  const handleExamTypeChange = (e) => {
    setExamType(
      examTypeOptions.find((exam) => exam.id.toString() === e.target.value)
    );
    setExamTypeChanged(true);
  };

  const handleModelTypeChange = (e) => {
    setModelType(
      modelTypeOptions?.find((model) => model.id.toString() === e.target.value)
    );
    setModelTypeChanged(true);
  };

  const cancel = () => {
    confirmAlert({
      message: 'Do you really want to cancel the exam?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              if (exam_id !== 'scan') {
                await axios.delete(`/patients/${patient_id}/exams/${exam_id}`);
              } else {
                const session = sessionStorage.getItem('examUniqueId');
                await axios.delete(
                  `/patients/${patient_id}/exams/${session
                    .split('&')
                    .slice(1)
                    .join('&')}`
                );
              }
            } catch (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error(
                  'Server responded with an error status:',
                  error.response.status
                );
                // Display error to the user, e.g., set it in a state variable
                // setErrorState(error.response.data);
              } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from the server');
              } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error during request setup:', error.message);
              }
            }

            navigate(`/patients/${patient_id}/exams`);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const updateEnableControls = (value) => {
    setEnableControls(value);
  };

  return (
    <div className='container md:border-1 shadow-xl border-gray-200 rounded-lg mx-auto w-full flex flex-col md:flex-row md:justify-between'>
      <Spinner show={loading} />

      <div className='min-w-[65vw] flex-1 flex flex-col px-4 py-6'>
        <div className='px-4 flex flex-col flex-1 flex-grow'>
          <div className='flex justify-between items-stretch py-2 select-none'>
            <div className='flex'>
              {draftStatus === 0 && (
                <button
                  type='button'
                  className='flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-900'
                  onClick={cancel}
                >
                  Cancel Exam <XCircleIcon className='w-5 h-5 ml-2' />
                </button>
              )}
            </div>
            <div className='flex h-full'>
              <div className='flex flex-col md:flex-row'>
                <button
                  disabled={!saveState}
                  type='button'
                  className={`ml-2 flex items-center rounded-md ${
                    saveState
                      ? 'bg-green-500 hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600'
                      : 'bg-gray-400 hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400'
                  } px-3 py-2 text-sm font-semibold text-white shadow-sm`}
                  onClick={saveExam}
                >
                  Save & Exit <CheckCircleIcon className='w-5 h-5 ml-2' />
                </button>
              </div>
              <div className='flex flex-col md:flex-row'>
                <button
                  disabled={!undoState}
                  type='button'
                  className={`ml-2 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                    undoState
                      ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300'
                      : 'bg-gray-400 cursor-not-allowed'
                  }`}
                  onClick={undoPoints}
                >
                  <div className='flex items-center'>
                    <ArrowLeftIcon className='w-5 h-5 mr-2' />
                    Undo
                  </div>
                </button>
                <button
                  disabled={!redoState}
                  type='button'
                  className={`ml-2 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                    redoState
                      ? 'bg-green-600 hover:bg-green-700 focus:outline-none focus:ring focus:border-green-300'
                      : 'bg-gray-400 cursor-not-allowed'
                  }`}
                  onClick={redoPoints}
                >
                  <div className='flex items-center'>
                    Redo <ArrowRightIcon className='w-5 h-5 ml-2' />
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className='w-full h-[90vh]'>
            <Canvas
              camera={{ position: [2, 0, 12.25], fov: 9 }}
              style={{
                backgroundColor: '#111a21',
                width: `100%`,
                height: `100%`,
              }}
            >
              <directionalLight color={'blue'} intensity={2} />
              <hemisphereLight
                skyColor={'yellow'}
                groundColor={'blue'}
                intensity={3}
              />
              <Suspense fallback={null}>
                {modelType && (
                  <Model
                    profile={profile}
                    position={[0.025, -0.9, 0]}
                    selectedLevel={selectedColor}
                    patient_id={patient_id}
                    exam_id={exam_id}
                    decalPoints={decalPoints}
                    previousPoints={previousPoints}
                    actionIndex={actionIndex}
                    setActionIndex={setActionIndex}
                    setPreviousPoints={setPreviousPoints}
                    setDecalPoints={setDecalPoints}
                    selectedPose={selectedPose}
                    selectedSize={selectedSize}
                    showNotes={showNotes}
                    updateEnableControls={updateEnableControls}
                    modelType={modelType}
                  />
                )}
              </Suspense>
              {enableControls && <OrbitControls ref={orbitControls} />}
            </Canvas>
          </div>
        </div>
      </div>
      <div className='border-gray-200 px-8 my-6 md:border-l border-t-0 pr-6 flex flex-col items-center select-none'>
        {/* Right column area */}
        <div className='border-b border-gray-200 w-full'>
          {/* Left column area */}
          <Header
            size='small'
            profile={profile}
            closeAction={closeAction}
            color={'gray'}
          />
        </div>
        <div className='flex flex-col py-2 w-full text-gray-900 text-sm font-medium'>
          <div className='w-full'>
            <span className='text-gray-600 font-bold'>Exam Date:</span>{' '}
            {examDate.created
              ? moment(examDate.created).format('MM/DD/YYYY h:mm A')
              : moment().format('MM/DD/YYYY h:mm A')}
          </div>

          <div className='w-full'>
            <span className='text-gray-600 font-bold'>Last updated:</span>{' '}
            {examDate.updated
              ? moment(examDate.updated).format('MM/DD/YYYY h:mm A')
              : moment().format('MM/DD/YYYY h:mm A')}
          </div>
        </div>
        <div className='flex items-center space-x-4 w-full'>
          <div className='text-sm font-medium text-gray-900'>Type:</div>
          <select
            id='examType'
            name='examType'
            value={(examType && examType.id) || ''}
            className={`block w-full rounded-lg my-2 border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 focus:ring-2 focus:ring-white-600 sm:text-sm sm:leading-6 ${
              error.examType ? 'ring-red-500' : 'ring-gray-300'
            }`}
            onChange={handleExamTypeChange}
          >
            <option value='' disabled>
              Select Exam Type
            </option>
            {examTypeOptions?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.type_name}
              </option>
            ))}
          </select>
        </div>
        <div className='flex items-center space-x-4 w-full'>
          <div className='text-sm font-medium text-gray-900'>Model:</div>
          <select
            id='modelType'
            name='modelType'
            value={modelType?.id || ''}
            className={`block w-full rounded-lg my-2 border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 focus:ring-2 focus:ring-white-600 sm:text-sm sm:leading-6 ${
              error.examType ? 'ring-red-500' : 'ring-gray-300'
            }`}
            onChange={handleModelTypeChange}
          >
            <option value='' disabled>
              Select Model Type
            </option>
            {modelTypeOptions?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.view_label}
              </option>
            ))}
          </select>
        </div>
        <div className='flex space-x-4 items-center w-full'>
          <div className='text-sm font-medium text-gray-900 w-8'>
            {showNotes ? 'Hide' : 'Show'} Note:
          </div>
          <ToggleButton
            showNotes={showNotes}
            toggleShowNotes={toggleShowNotes}
          />
        </div>
        <div className='mt-2'>
          <RadioGroup value={selectedColor} onChange={setSelectedColor}>
            <RadioGroup.Label className='block text-sm text-center font-medium leading-6 text-gray-900 mb-2'>
              Pain Level
            </RadioGroup.Label>
            <div className='flex justify-center items-center space-x-2'>
              {colors?.map((color) => (
                <React.Fragment key={color.value}>
                  {color.value !== 4 ? (
                    <RadioGroup.Option
                      value={color.value}
                      className={({ active, checked }) =>
                        classNames(
                          color.selectedColor,
                          active && checked ? 'ring ring-offset-1' : '',
                          !active && checked ? 'ring-2' : '',
                          'relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none rounded-full'
                        )
                      }
                    >
                      <span
                        aria-hidden='true'
                        className={classNames(
                          color.bgColor,
                          'text-center h-6 w-6 border border-black border-opacity-10 rounded-full transition-transform transform-gpu hover:scale-125'
                        )}
                      >
                        {color.name}
                      </span>
                    </RadioGroup.Option>
                  ) : (
                    <RadioGroup.Option
                      value={color.value}
                      className={({ active, checked }) =>
                        classNames(
                          color.selectedColor,
                          active && checked ? 'ring ring-offset-1' : '',
                          !active && checked ? 'ring-2' : '',
                          'relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none rounded-full'
                        )
                      }
                    >
                      <span
                        aria-hidden='true'
                        style={{ backgroundColor: color.bgColor }}
                        className={classNames(
                          'text-center h-6 w-6 border border-black border-opacity-10 rounded-full transition-transform transform-gpu hover:scale-125'
                        )}
                      ></span>
                      <span
                        className={`absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-1 text-xs text-grey-700 bg-white p-2 rounded-md shadow-md opacity-1 transition-opacity duration-300 pointer-events-none`}
                      >
                        {color.name}
                      </span>
                    </RadioGroup.Option>
                  )}
                </React.Fragment>
              ))}
            </div>
          </RadioGroup>
        </div>
        <div className='my-2'>
          <RadioGroup value={selectedPose} onChange={setSelectedPose}>
            <RadioGroup.Label className='block text-sm text-center font-medium leading-6 text-gray-900'>
              Pose
            </RadioGroup.Label>
            <div className='mt-3 flex flex-wrap gap-2 items-center justify-center'>
              {poses?.map((pose) => (
                <RadioGroup.Option
                  key={pose.value}
                  value={pose.value}
                  className={({ active, checked }) =>
                    classNames(
                      active && checked ? 'ring ring-offset-1' : '',
                      !active && checked ? 'ring-2' : '',
                      'relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none rounded-full'
                    )
                  }
                >
                  <span
                    aria-hidden='true'
                    className={classNames(
                      'text-center h-8 w-16 md:w-20 border border-black border-opacity-10 rounded-full flex items-center justify-center',
                      `bg-gradient-to-r hover:font-bold from-${pose.color}-400 to-${pose.color}-600 text-black`,
                      'text-xs md:text-sm overflow-hidden whitespace-nowrap',
                      'transition duration-300 ease-in-out transform hover:scale-105'
                    )}
                  >
                    {pose.name}
                  </span>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        <div className='mb-2'>
          <RadioGroup value={selectedSize} onChange={setSelectedSize}>
            <RadioGroup.Label className='block text-sm text-center font-medium leading-6 text-gray-900'>
              Size
            </RadioGroup.Label>
            <div className='mt-2 flex flex-wrap gap-2'>
              {sizes?.map((size) => (
                <RadioGroup.Option
                  key={size.value}
                  value={size.value}
                  className={({ active, checked }) =>
                    classNames(
                      active && checked ? 'ring ring-offset-1' : '',
                      !active && checked ? 'ring-2' : '',
                      'relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none rounded-full'
                    )
                  }
                >
                  <span
                    aria-hidden='true'
                    className={classNames(
                      'text-center h-8 w-8 border border-black border-opacity-10 rounded-full relative',
                      `bg-${size.color}-500`,
                      `text-black hover:font-bold flex items-center justify-center`,
                      'transition duration-300 ease-in-out transform hover:scale-105'
                    )}
                    onMouseEnter={() => setShowTooltip(size.value)}
                    onMouseLeave={() => setShowTooltip(null)}
                  >
                    {size.value}
                    {showTooltip === size.value && (
                      <span className='absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 text-xs text-gray-700 bg-white p-2 rounded-md shadow-md opacity-1 transition-opacity duration-300 pointer-events-none'>
                        {size.name}
                      </span>
                    )}
                  </span>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        <textarea
          ref={textRef}
          className='border focus:border-gray-500 w-full rounded-lg focus:outline-none transition px-2 py-2 mt-6'
          placeholder='Note'
          rows={5}
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
            setNoteChanged(true);
          }}
          onResize={() => {
            handleResize();
          }}
        />
        <div className='flex w-full'>
          <button
            type='button'
            className='mt-2 rounded-md px-3 py-2 text-sm font-semibold 
            text-white shadow-sm bg-green-600 hover:bg-green-700 focus:outline-none focus:ring focus:border-green-300'
            onClick={() => {
              orbitControls.current.reset();
            }}
          >
            Reset Viewport
          </button>
        </div>
      </div>
    </div>
  );
}

const ToggleButton = ({ showNotes, toggleShowNotes }) => {
  return (
    <button
      onClick={toggleShowNotes}
      className={`relative inline-flex items-center w-12 h-6 bg-${
        showNotes ? 'green' : 'red'
      }-500 rounded-full p-1 transition duration-300 ease-in-out`}
    >
      <span
        className={`block w-5 h-5 rounded-full transform transition-transform duration-300 ease-in-out ${
          showNotes ? 'translate-x-full' : 'translate-x-0'
        } bg-white shadow-md`}
      />
    </button>
  );
};
