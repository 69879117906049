import * as THREE from "three";
import { useRef, useState, useEffect } from "react";
import { Html } from "@react-three/drei";
import { useThree } from "@react-three/fiber";

function NoteDisplay({
  point,
  transparency,
  handlePointClick,
  setDisplayNoteTransparency,
}) {
  let backgroundColor, textColor;
  const { pain_level, notes, position } = point;
  switch (pain_level) {
    case 0:
      backgroundColor = `rgb(135, 206, 250)`;
      textColor = `rgb(255, 255, 255)`;
      break;

    case 1:
      backgroundColor = `rgb(0, 128, 0)`;
      textColor = `rgb(200, 200, 200)`;
      break;

    case 2:
      backgroundColor = `rgb(255, 255, 0)`;
      textColor = `rgb(100,100,100)`;
      break;

    case 3:
      backgroundColor = `rgb(255, 0, 0)`;
      textColor = `rgb(255, 255, 255)`;
      break;

    case 4:
      backgroundColor = `rgb(200, 162, 200)`;
      textColor = `rgb(255, 255, 255)`;
      break;

    default:
      break;
  }

  const { size } = useThree(); // Ensure you have access to size
  const ref = useRef();

  const [hovered, setHovered] = useState(false);

  const over = (e) => {
    e.stopPropagation();
    setHovered(true);
    setDisplayNoteTransparency(0.2);
  };
  const out = () => {
    setHovered(false);
    setDisplayNoteTransparency(0.7);
  };

  // Change the mouse cursor on hover
  useEffect(() => {
    if (hovered) document.body.style.cursor = "pointer";
    return () => (document.body.style.cursor = "auto");
  }, [hovered]);

  // This is just an example; you can customize the onClick action
  const onClick = (e) => {
    e.stopPropagation();
    handlePointClick(point);
    console.log("Note clicked:", notes);
  };

  return (
    <Html
      position={[position[0], position[1], position[2] - 0.01]}
      zIndexRange={hovered ? 999 : 1}
    >
      <div
        ref={ref}
        onClick={onClick}
        // onDoubleClick={(e) => {
        //   e.stopPropagation();
        //   handlePointClick(point);
        // }}
        onPointerOver={over}
        onPointerOut={out}
        style={{
          userSelect: "none",
        }}
      >
        <div
          style={{
            zIndex: hovered ? 1 : 0,
            opacity: hovered ? 1 : transparency,
            background: backgroundColor,
            borderRadius: "10px",
            // height: hovered ? "auto" : "fit-content",
            width:
              notes.length * 10 < size.width / 5
                ? hovered
                  ? `${notes.length * 10}px`
                  : `${notes.length * 9}px`
                : `${size.width / 5}px`,
            padding: notes ? "5px" : "0",
            // whiteSpace: "nowrap",
            // wordWrap: "break-word",
            color: textColor,
            overflow: hovered ? "visible" : "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: hovered ? "unset" : 2, // Adjust the number of lines you want to show
            textOverflow: "ellipsis",
            fontWeight: hovered || transparency === 1 ? "bold" : "lighter",
            transition: "all 0.2s ease-in",
          }}
        >
          {notes}
        </div>
      </div>
    </Html>
  );
}

export default NoteDisplay;
