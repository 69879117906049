import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";
import UpdatePatientInfo from "./UpdatePatientInfo";
import Spinner from "../components/Spinner";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { convertDateString } from "../utils/ConvertDateString";

function PatientList() {
  const { authTokens } = useContext(AuthContext);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [openEditView, setOpenEditView] = useState(false);
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage, setPatientsPerPage] = useState(40);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/patients");
      setPatients(res.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const [showTestPatients, setShowTestPatients] = useState(true);
  const toggleShowPatients = () => {
    setShowTestPatients(!showTestPatients);
  };
  useEffect(() => {
    if (authTokens) {
      fetchData();
    }
  }, [authTokens]);
  const editPatientInfo = (e, patient) => {
    e.preventDefault();
    setSelectedPatient(patient);
    setOpenEditView(true);
  };

  const editPatientStatus = async (id, status) => {
    confirmAlert({
      message: "Do you really want to archive this patient?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const res = await axios.post(`/patients/${id}`, {
                status: status,
              });
              fetchData();
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const upsertModalClosed = () => {
    setOpenEditView(false);
    fetchData();
  };

  // Get current patients
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = patients
    .slice()
    .sort(
      (a, b) =>
        a.last_name.localeCompare(b.last_name) ||
        a.first_name.localeCompare(b.first_name)
    )
    .filter((person) => person.status === 1)
    .filter((person) => person.type === showTestPatients)
    .slice(indexOfFirstPatient, indexOfLastPatient);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // console.log(
  //   currentPage,
  //   indexOfFirstPatient,
  //   indexOfLastPatient,
  //   patients.length
  // );
  return (
    <div className="container mx-auto p-6">
      <Spinner show={loading} />
      <div className="py-8">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-3xl font-bold text-indigo-800">Patients</h1>
            <p className="mt-2 text-sm text-gray-600">
              A list of all the patients in your account, including their name,
              title, email, and role.
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <button
              type="button"
              className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition duration-300"
              onClick={() => {
                setSelectedPatient(null);
                setOpenEditView(true);
              }}
            >
              Add Patient
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
            <thead className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white">
              <tr>
                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">
                  
                </th>
                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">
                  First
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Family
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  DOB
                </th>
                {/* <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Age
                </th> */}
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Sex
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Language
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Phone
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Email
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Username
                </th>
                <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <ToggleButton
                    showPatients={showTestPatients}
                    toggleShowPatients={toggleShowPatients}
                  />
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {currentPatients
                .slice()
                .sort(
                  (a, b) =>
                    a.last_name.localeCompare(b.last_name) ||
                    a.first_name.localeCompare(b.first_name)
                )
                .filter((person) => person.status === 1)
                .map((person) => (
                  <tr key={person.id}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.type ? "Actual" : "Test"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.first_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.last_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {convertDateString(person.birthday, false)}
                    </td>
                    {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      Age Calculation
                    </td> */}
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.sex === 0 ? "Male" : "Female"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.preferred_language}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.phone}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                      {person.username}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                        className="bg-red-500 text-white px-2 py-1 mx-1 rounded-md hover:bg-red-600 transition duration-300"
                        onClick={() => {
                          editPatientStatus(person.profile_id, 0);
                        }}
                      >
                        Archive
                      </button>
                      <a
                        href="#"
                        onClick={(e) => editPatientInfo(e, person)}
                        className="bg-indigo-500 text-white px-2 py-1 mx-1 rounded-md hover:bg-indigo-600 transition duration-300"
                      >
                        Edit
                        <span className="sr-only">, {person.name}</span>
                      </a>
                      <Link
                        to={`/patients/${person.id}/exams`}
                        className="bg-blue-500 text-white px-2 py-1 mx-1 rounded-md hover:bg-blue-600 transition duration-300"
                      >
                        Go To Chart
                        <span className="sr-only">, {person.name}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <div className="my-4 flex justify-end space-x-4 mr-4">
            <div className="flex items-center">
              <span className="mr-2 text-sm">Patients per page:</span>
              <select
                className="px-2 py-1 border rounded-md"
                onChange={(e) =>
                  setPatientsPerPage(parseInt(e.target.value, 10))
                }
                value={patientsPerPage}
              >
                {[5, 10, 15, 20].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="px-4 py-2 w-24 text-sm text-center font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition duration-300"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="px-4 py-2 w-24 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition duration-300"
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastPatient >= patients.length}
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
      <UpdatePatientInfo
        selectedPatient={selectedPatient}
        open={openEditView}
        onClose={upsertModalClosed}
      />
    </div>
  );
}
const ToggleButton = ({ showPatients, toggleShowPatients }) => {
  return (
    <button
      onClick={toggleShowPatients}
      className={`relative inline-flex items-center w-12 h-6 bg-${
        showPatients ? "blue" : "gray"
      }-500 rounded-full p-1 transition duration-300 ease-in-out`}
    >
      <span
        className={`block w-5 h-5 rounded-full transform transition-transform duration-300 ease-in-out ${
          showPatients ? "translate-x-full" : "translate-x-0"
        } bg-white shadow-md`}
      />
    </button>
  );
};

export default PatientList;
