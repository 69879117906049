import React, { useEffect, useRef, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { colors, sizes } from "../utils/examConfig";
export const InputNote = (props) => {
  const textareaRef = useRef(null);
  const timeoutRef = useRef(null);
  const [cursor, setCursor] = useState(null);
  const {
    value,
    onChange,
    clickedPoint,
    setClickedPainPoint,
    handleSaveNotes,
    enableCamera,
    updateEnableCamera,
    handleDeletePoint,
  } = props;

  const [selectedColor, setSelectedColor] = useState(0);
  const [selectedSize, setSelectedSize] = useState(1);
  const [showTooltip, setShowTooltip] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setSelectedColor(clickedPoint.pain_level);
    setSelectedSize(clickedPoint.size);
  }, [clickedPoint]);
  useEffect(() => {
    const input = textareaRef.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [textareaRef, cursor, value]);
  const handleInputChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleMouseDown = (e) => {
    setDragging(true);
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };
  return (
    <div
      className="absolute top-1/2 left-1/2 w-80 p-6 bg-white shadow-lg rounded-lg"
      onClick={(e) => e.stopPropagation()}
      onPointerDown={(e) => {
        timeoutRef.current = setTimeout(() => {
          updateEnableCamera(false);
          handleMouseDown(e);
        }, 300);
      }}
      onPointerUp={(e) => {
        clearTimeout(timeoutRef.current);
        updateEnableCamera(true);
        handleMouseUp();
      }}
      onMouseMove={(e) => handleMouseMove(e)}
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
        background: "rgba(255,255,255,0.5)",
        cursor: dragging ? "grabbing" : "grab",
      }}
    >
      <h2 className="text-2xl font-semibold mb-4 text-[rgb(80, 80, 80)]">
        Input Notes
      </h2>
      <div className="">
        <textarea
          className="w-full h-24 p-2 border rounded-md mb-2 bg-[rgba(255,255,255,0.5)] hover:border-[white] focus:border-[white] focus:outline-none"
          value={value}
          onChange={handleInputChange}
          // onClick={(e) => e.stopPropagation()}
          placeholder="Type your notes here..."
          ref={textareaRef}
        />
      </div>
      {/* <div className="mb-4">
        <RadioGroup value={selectedColor} onChange={setSelectedColor}>
          <RadioGroup.Label className="block text-sm text-center font-medium leading-6 text-gray-900 mb-2">
            Pain Level
          </RadioGroup.Label>
          <div className="flex justify-center items-center space-x-2">
            {colors.map((color) => (
              <React.Fragment key={color.value}>
                {color.value !== 4 ? (
                  <RadioGroup.Option
                    value={color.value}
                    className={({ active, checked }) =>
                      classNames(
                        color.selectedColor,
                        active && checked ? "ring ring-offset-1" : "",
                        !active && checked ? "ring-2" : "",
                        "relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none rounded-full"
                      )
                    }
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        color.bgColor,
                        "text-center h-6 w-6 border border-black border-opacity-10 rounded-full  transition-transform transform-gpu hover:scale-125"
                      )}
                    >
                      {color.name}
                    </span>
                  </RadioGroup.Option>
                ) : (
                  <RadioGroup.Option
                    value={color.value}
                    className={({ active, checked }) =>
                      classNames(
                        color.selectedColor,
                        active && checked ? "ring ring-offset-1" : "",
                        !active && checked ? "ring-2" : "",
                        "relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none rounded-full"
                      )
                    }
                  >
                    <span
                      aria-hidden="true"
                      style={{ backgroundColor: color.bgColor }}
                      className={classNames(
                        "text-center h-6 w-6 border border-black border-opacity-10 rounded-full transition-transform transform-gpu hover:scale-125"
                      )}
                    ></span>
                  </RadioGroup.Option>
                )}
              </React.Fragment>
            ))}
          </div>
        </RadioGroup>
      </div>
      <div className="mb-4">
        <RadioGroup value={selectedSize} onChange={setSelectedSize}>
          <RadioGroup.Label className="block text-sm text-center font-medium leading-6 text-gray-900">
            Size
          </RadioGroup.Label>
          <div className="mt-2 flex justify-center items-center flex-wrap gap-2">
            {sizes.map((size) => (
              <RadioGroup.Option
                key={size.value}
                value={size.value}
                className={({ active, checked }) =>
                  classNames(
                    active && checked ? "ring ring-offset-1" : "",
                    !active && checked ? "ring-2" : "",
                    "relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none rounded-full"
                  )
                }
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    "text-center h-8 w-8 border border-black border-opacity-10 rounded-full relative",
                    `bg-${size.color}-500`,
                    "text-black font-bold flex items-center justify-center",
                    "transition duration-300 ease-in-out transform hover:scale-105"
                  )}
                  onMouseEnter={() => setShowTooltip(size.value)}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  {size.value}
                  {showTooltip === size.value && (
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 text-xs text-gray-700 bg-white p-2 rounded-md shadow-md opacity-[0.8] transition-opacity duration-300 pointer-events-none">
                      {size.name}
                    </span>
                  )}
                </span>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div> */}
      <div className="flex justify-end">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-blue-600"
          onClick={() => setClickedPainPoint(null)}
        >
          Close
        </button>
        <button
          className="bg-red-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-red-600"
          onClick={handleDeletePoint}
        >
          Delete
        </button>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          onClick={() => handleSaveNotes(selectedColor, selectedSize)}
        >
          Save
        </button>
      </div>
    </div>
  );
};
