import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useAxios from "../utils/useAxios";
import moment from "moment";
import Spinner from "../components/Spinner";
import "../index.css";
function Exams() {
  // const { user } = useContext(AuthContext);
  const axios = useAxios();
  const navigate = useNavigate();
  const { patient_id } = useParams();

  const [exams, setExams] = useState([]);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [examTypes, setExamTypes] = useState([]);
  const [filteredExamDraft, setFilteredExamDraft] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const fetchExams = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/patients/${patient_id}/exams`);
      setExams(res.data.exams);
      setProfile(res.data.profile);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const fetchExamTypes = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/examtypes`);
      const typesObject = {};
      res.data &&
        res.data.map((exam_type) => {
          typesObject[exam_type.id] = exam_type.type_name;
        });
      setExamTypes(typesObject);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchExams();
    fetchExamTypes();
  }, []);

  const goToExamDetail = (exam_id) => {
    navigate(`/patients/${patient_id}/exams/${exam_id}`);
  };
  const examDraft = JSON.parse(localStorage.getItem("examDraft")) || [];
  let newDraft = examDraft;
  useEffect(() => {
    if (patient_id) {
      if (examDraft && examDraft.length > 0) {
        examDraft.map(async (item) => {
          // console.log("item", item);
          // Invoke the arrow function here
          try {
            const res = await axios.post(
              `/patients/${patient_id}/exams/upsert`,
              item
            );
            if (res.data.message === "success") {
              newDraft = newDraft.filter(
                (newItem) => newItem.exam_id !== item.exam_id
              );
              // console.log("newDraft", newDraft);
              localStorage.setItem("examDraft", JSON.stringify(newDraft));
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
      setFilteredExamDraft(
        newDraft.filter((item) => {
          const parts = item.exam_id && item.exam_id.split("&");
          return parts[0] === patient_id;
        })
      );
    }
  }, [patient_id]);
  // console.log(newDraft, filteredExamDraft);
  return (
    <div className="container mx-auto border-1 border-gray-200 shadow-xl rounded-lg bg-gray-50">
      <Spinner show={loading} />
      <div className="flex justify-between items-center p-8 bg-gray-50 shadow-sm border-b border-gray-200 bg-rounded-t-lg">
        <Header
          profile={profile}
          closeAction={() => navigate("/patients")}
          color={"gray"}
        />
        <Link
          to="/patients"
          className="w-32 text-white text-center px-4 py-3 rounded-md bg-blue-500 hover:bg-blue-600 font-bold shadow-md hover:scale-110 transition ease-in-out duration-300"
        >
          Patients List
        </Link>
      </div>
      <div className="lg:flex">
        <div
          className="lg:w-[40%] w-full bg-gray-50 shadow-md p-8 custom-scrollbar"
          style={{ height: "calc(100vh - 90px - 9rem)", overflowY: "auto" }}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="text-2xl font-bold text-gray-600">
              PainScans History
            </div>
          </div>
          {filteredExamDraft &&
            filteredExamDraft.map((draft, index) => (
              <div
                key={index}
                className="flex flex-col items-start relative rounded-lg border border-white bg-white p-6 shadow-md hover:border-gray-200 cursor-pointer mt-6 transition duration-300 transform hover:scale-105"
                onClick={() => {
                  goToExamDetail(draft.exam_id.split("&").slice(1).join("&"));
                }}
              >
                <div className="text-gray-700 font-medium flex w-full justify-between">
                  <div className="">
                    Exam Type:{" "}
                    {(examTypes &&
                      draft.exam_type &&
                      examTypes[draft.exam_type]) ||
                      "Not set"}
                  </div>
                  <div className="text-end text-red-500">DRAFT - UNSAVED</div>
                </div>

                {/* Exam Information */}
                <div className="">
                  <div className="text-medium font-medium text-blue-500">
                    {moment(draft.created_at).format("MM/DD/YYYY h:mm A")}
                  </div>
                </div>
                <div
                  className="text-gray-500"
                  style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2, // Adjust the number of lines you want to show
                    textOverflow: "ellipsis",
                  }}
                >
                  Note: {draft.exam_note}
                </div>
              </div>
            ))}
          {exams.length > 0 &&
            exams
              .sort((a, b) => {
                // Check if exams have temporary_id
                const hasTemporaryIdA = a.temporary_id !== null;
                const hasTemporaryIdB = b.temporary_id !== null;

                // Prioritize exams with temporary_id
                if (hasTemporaryIdA && !hasTemporaryIdB) {
                  return -1;
                } else if (!hasTemporaryIdA && hasTemporaryIdB) {
                  return 1;
                } else {
                  // Compare created_at dates for sorting
                  const createdDateComparison =
                    new Date(b.created_at) - new Date(a.created_at);

                  // If created_at dates are the same, compare other properties (e.g., exam_type)
                  return createdDateComparison !== 0
                    ? createdDateComparison
                    : examTypes[a.exam_type_id].localeCompare(
                        examTypes[b.exam_type_id]
                      );
                }
              })
              .map((exam) => (
                <div
                  key={exam.id}
                  className="flex flex-col items-start relative rounded-lg border border-white bg-white p-6 shadow-md hover:border-gray-200 cursor-pointer mt-6 transition duration-300 transform hover:scale-105"
                  onClick={() => {
                    exam.draft_status === 0
                      ? goToExamDetail(
                          exam.temporary_id.split("&").slice(1).join("&")
                        )
                      : goToExamDetail(exam.id);
                  }}
                >
                  <div className="text-gray-700 font-medium flex w-full justify-between">
                    <div className="">
                      Exam Type:{" "}
                      {(examTypes &&
                        exam.exam_type_id &&
                        examTypes[exam.exam_type_id]) ||
                        "Not set"}
                    </div>
                    <div className="text-end">
                      {exam.temporary_id ? (
                        <div className="text-red-500">DRAFT - UNSAVED</div>
                      ) : (
                        <div className="text-blue-500">COMPLETED</div>
                      )}
                    </div>
                  </div>
                  {/* Exam Information */}
                  <div className="">
                    <div className="text-medium font-medium text-blue-500">
                      {moment(exam.created_at).format("MM/DD/YYYY h:mm A")}
                    </div>
                  </div>
                  <div
                    className="text-gray-500"
                    style={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2, // Adjust the number of lines you want to show
                      textOverflow: "ellipsis",
                    }}
                  >
                    Note: {exam.exam_note}
                  </div>
                </div>
              ))}
          {/* {exams.length > 0 &&
            exams.map((exam) => (
              <div
                key={exam.id}
                className="flex items-center space-x-8 relative rounded-lg border border-white bg-white p-6 shadow-md hover:border-gray-400 cursor-pointer mt-6 transition duration-300 transform hover:scale-105"
                onClick={() => goToExamDetail(exam.id)}
              >
                <div className="text-lg font-bold text-indigo-600">
                  {moment(exam.created_at).format("MM/DD/YYYY h:mm A")}
                </div>
                <div className="text-gray-700 font-medium">Details</div>
              </div>
            ))} */}
        </div>
        <div className="p-8">
          <Link
            onClick={() => sessionStorage.removeItem("examUniqueId")}
            to={`/patients/${patient_id}/exams/scan`}
            className="block w-full text-center rounded-md bg-blue-500 hover:bg-blue-600 px-6 py-3 text-lg font-bold text-white shadow-md transition duration-300 transform hover:scale-105"
          >
            New Scan
          </Link>

          <div className="pt-8">
            <button
              disabled={isDisabled}
              className={`${
                isDisabled
                  ? "bg-gray-500 cursor-not-allowed"
                  : " transition bg-blue-500 duration-300 transform hover:scale-105 hover:bg-blue-600"
              } block w-full text-center rounded-md px-6 py-3 text-lg font-bold text-white shadow-md`}
              onClick={() => navigate(`/patients/${patient_id}/exams/scan`)}
            >
              Compare Scan
            </button>
          </div>

          <div className="pt-8">
            <button
              disabled={isDisabled}
              className={`${
                isDisabled
                  ? "bg-gray-500 cursor-not-allowed"
                  : " transition bg-blue-500 duration-300 transform hover:scale-105 hover:bg-blue-600"
              } block w-full text-center rounded-md px-6 py-3 text-lg font-bold text-white shadow-md`}
              onClick={() => navigate(`/patients/${patient_id}/exams/scan`)}
            >
              Merge 2 Scans
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Exams;
