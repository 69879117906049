import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useAxios from "../utils/useAxios";
import Spinner from "../components/Spinner";
// import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

export default function UpdatePatientInfo(props) {
  const piForm = useRef();
  const [patientInfo, setPatientInfo] = useState(null);
  const [originalUsername, setOriginalUsername] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  useEffect(() => {
    if (props.selectedPatient != null) {
      setPatientInfo({ ...props.selectedPatient, password: "" });
      setOriginalUsername(props.selectedPatient.username);
    } else {
      initPatientInfo();
    }
  }, [props]);
  const initPatientInfo = () => {
    setPatientInfo({
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      profile_id: null,
      sex: -1,
      birthday: null,
      height: "",
      weight: "",
      preferred_language: "English",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "us",
      zipcode: "",
      type: true,
    });
    setError({});
  };

  const submitForm = () => {
    piForm.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      !patientInfo.first_name ||
      !patientInfo.last_name ||
      patientInfo.sex < 0 ||
      !patientInfo.birthday ||
      !patientInfo.phone
    ) {
      setError({
        first_name: !patientInfo.first_name,
        last_name: !patientInfo.last_name,
        sex: null,
        birthday: !patientInfo.birthday,
        phone: !patientInfo.phone,
      });
    } else {
      setLoading(true);
      try {
        const res = await axios.post("/patients/upsert", {
          ...patientInfo,
          username_updated: originalUsername !== patientInfo.username,
        });
        if (res.data.message === "failure") {
          alert("The username already exists! Please use other username!");
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      setOriginalUsername(null);
      setError({});
      props.onClose();
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.onClose}>
        <Spinner show={loading} />
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    ref={piForm}
                    action="#"
                    method="POST"
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    onSubmit={onSubmit}
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="rounded-tl-lg bg-gradient-to-r from-indigo-600 to-blue-500 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              {props.selectedPatient !== null
                                ? "Edit Patient"
                                : "Create Patient"}
                            </Dialog.Title>
                            {/* <p className="text-sm text-gray-500">
                              Get started by filling in the information below to create your new project.
                            </p> */}
                          </div>
                          {/* <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => props.onClose()}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div> */}
                        </div>
                      </div>
                      {patientInfo != null && (
                        <>
                          {/* Divider container */}
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                            {/* First Name, Last Name */}
                            <div className="px-4 md:flex">
                              <div className="space-y-2 px-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:space-y-0 sm:px-2 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="first_name"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                  >
                                    First Name
                                    <span className="text-red-700"> *</span>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 ${
                                      error.first_name ? "ring-red-500" : ""
                                    }`}
                                    value={patientInfo?.first_name || ""}
                                    onChange={(e) =>
                                      setPatientInfo({
                                        ...patientInfo,
                                        first_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="space-y-2 px-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:space-y-0 sm:px-2 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="last_name"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                  >
                                    Last Name
                                    <span className="text-red-700"> *</span>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 ${
                                      error.last_name ? "ring-red-500" : ""
                                    }`}
                                    value={patientInfo?.last_name || ""}
                                    onChange={(e) =>
                                      setPatientInfo({
                                        ...patientInfo,
                                        last_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Email */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="email"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Email
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.email || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      email: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {/* Patient Type */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="email"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Actual Patient
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="checkbox"
                                  id="demoPatient"
                                  name="demoPatient"
                                  className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  checked={patientInfo?.type || false}
                                  onChange={(e) => {
                                    console.log("pati type", patientInfo.type);
                                    setPatientInfo({
                                      ...patientInfo,
                                      type: e.target.checked,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {/* Username */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="username"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Username
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="username"
                                  id="username"
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                                  value={patientInfo?.username || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      username: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* Password */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="password"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Password
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.password || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      password: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* Confirm Password */}
                            {/* <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
												<div>
													<label
														htmlFor="password"
														className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
													>
														Confirm Password
													</label>
												</div>
												<div className="sm:col-span-2">
													<input
														type="password"
														name="confirm_password"
														id="confirm_password"
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
													/>
												</div>
											</div> */}

                            {/* sex */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="sex"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Sex<span className="text-red-700"> *</span>
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  id="sex"
                                  name="sex"
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                                    error.sex === null ? "ring-red-500" : ""
                                  }`}
                                  value={
                                    patientInfo?.sex === null
                                      ? -1
                                      : patientInfo?.sex
                                  }
                                  onChange={(e) => {
                                    setPatientInfo({
                                      ...patientInfo,
                                      sex: e.target.value,
                                    });
                                  }}
                                >
                                  <option value={-1}>Select Gender</option>
                                  <option value={0}>Male</option>
                                  <option value={1}>Female</option>
                                </select>
                              </div>
                            </div>

                            {/* date of birth */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="birthday"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Date of Birth
                                  <span className="text-red-700"> *</span>
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="date"
                                  name="birthday"
                                  id="birthday"
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 ${
                                    error.birthday ? "ring-red-500" : ""
                                  }`}
                                  value={patientInfo?.birthday || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      birthday: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* height */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="height"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Height(inches)
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="height"
                                  id="height"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.height || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      height: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* weight */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="weight"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Weight(pounds)
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="weight"
                                  id="weight"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.weight || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      weight: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* Preferred Language */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="preferred_language"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Preferred Language
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  id="preferred_language"
                                  name="preferred_language"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  value={
                                    patientInfo?.preferred_language || "English"
                                  }
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      preferred_language: e.target.value,
                                    })
                                  }
                                >
                                  <option value={"English"}>English</option>
                                  <option value={"French"}>French</option>
                                  <option value={"Spanish"}>Spanish</option>
                                </select>
                              </div>
                            </div>

                            {/* Phone */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="phone"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Phone<span className="text-red-700"> *</span>
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="tel"
                                  name="phone"
                                  id="phone"
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 ${
                                    error.phone ? "ring-red-500" : ""
                                  }`}
                                  value={patientInfo?.phone || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      phone: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* Address */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="address"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Address
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="address"
                                  id="address"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.address || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      address: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* City */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="city"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  City
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.city || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      city: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* state */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="state"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  State
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="state"
                                  id="state"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.state || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      state: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* country */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Country
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  id="country"
                                  name="country"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  value={patientInfo?.country || "us"}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      country: e.target.value,
                                    })
                                  }
                                >
                                  <option value={"us"}>US</option>
                                  <option value={"fr"}>France</option>
                                </select>
                              </div>
                            </div>

                            {/* zipcode */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="zipcode"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Zipcode
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="zipcode"
                                  id="zipcode"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                  value={patientInfo?.zipcode || ""}
                                  onChange={(e) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      zipcode: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* Privacy */}
                            {/* <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
												<legend className="sr-only">Privacy</legend>
												<div className="text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
													Privacy
												</div>
												<div className="space-y-5 sm:col-span-2">
													<div className="space-y-5 sm:mt-0">
														<div className="relative flex items-start">
															<div className="absolute flex h-6 items-center">
																<input
																	id="public-access"
																	name="privacy"
																	aria-describedby="public-access-description"
																	type="radio"
																	className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
																	defaultChecked
																/>
															</div>
															<div className="pl-7 text-sm leading-6">
																<label htmlFor="public-access" className="font-medium text-gray-900">
																	Public access
																</label>
																<p id="public-access-description" className="text-gray-500">
																	Everyone with the link will see this project
																</p>
															</div>
														</div>
														<div className="relative flex items-start">
															<div className="absolute flex h-6 items-center">
																<input
																	id="restricted-access"
																	name="privacy"
																	aria-describedby="restricted-access-description"
																	type="radio"
																	className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
																/>
															</div>
															<div className="pl-7 text-sm leading-6">
																<label htmlFor="restricted-access" className="font-medium text-gray-900">
																	Private to Project Members
																</label>
																<p id="restricted-access-description" className="text-gray-500">
																	Only members of this project would be able to access
																</p>
															</div>
														</div>
														<div className="relative flex items-start">
															<div className="absolute flex h-6 items-center">
																<input
																	id="private-access"
																	name="privacy"
																	aria-describedby="private-access-description"
																	type="radio"
																	className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
																/>
															</div>
															<div className="pl-7 text-sm leading-6">
																<label htmlFor="private-access" className="font-medium text-gray-900">
																	Private to you
																</label>
																<p id="private-access-description" className="text-gray-500">
																	You are the only one able to access this project
																</p>
															</div>
														</div>
													</div>
													<hr className="border-gray-200" />
													<div className="flex flex-col items-start space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
														<div>
															<a
																href="#"
																className="group flex items-center space-x-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-900"
															>
																<LinkIcon
																	className="h-5 w-5 text-indigo-500 group-hover:text-indigo-900"
																	aria-hidden="true"
																/>
																<span>Copy link</span>
															</a>
														</div>
														<div>
															<a
																href="#"
																className="group flex items-center space-x-2.5 text-sm text-gray-500 hover:text-gray-900"
															>
																<QuestionMarkCircleIcon
																	className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
																	aria-hidden="true"
																/>
																<span>Learn more about sharing</span>
															</a>
														</div>
													</div>
												</div>
											</fieldset> */}
                          </div>
                        </>
                      )}
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => props.onClose()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={submitForm}
                        >
                          {patientInfo && patientInfo.id == null
                            ? "Create"
                            : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
