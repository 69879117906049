import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
import useAxios from "../utils/useAxios";
import UpdatePatientInfo from "./UpdatePatientInfo";
import Spinner from "../components/Spinner";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import UpdateExamTypeInfo from "./UpdateExamTypeInfo";

function ExamType() {
  const { authTokens } = useContext(AuthContext);
  const [examTypeOptions, setExamTypeOptions] = useState([]);
  const [selectedExamType, setSelectedExamType] = useState(null);
  const [openEditView, setOpenEditView] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState("all");
  const axios = useAxios();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/examtypes");
      setExamTypeOptions(res.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (authTokens) {
      fetchData();
    }
  }, [authTokens]);

  const editExamTypeInfo = (e, examType) => {
    e.preventDefault();
    setSelectedExamType(examType);
    setOpenEditView(true);
  };

  const deleteExamType = async (id, status) => {
    try {
      const res = await axios.delete(`/examtypes/${id}`);
      confirmAlert({
        message: res.data.message,
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                const res = await axios.delete(
                  `/examtypes/${id}?confirm_deletion=True`
                );
              } catch (error) {
                console.log(error);
              }
              fetchData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };
  const upsertModalClosed = () => {
    setOpenEditView(false);
    fetchData();
  };

  return (
    <div className="container mx-auto p-6">
      <Spinner show={loading} />
      <div className="py-8">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-3xl font-bold text-indigo-800">Exam Types</h1>
            <p className="mt-2 text-sm text-gray-600">
              A list of all the exam types
            </p>
          </div>
          <div>
            <button
              type="button"
              className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition duration-300"
              onClick={() => {
                setSelectedExamType(null);
                setOpenEditView(true);
              }}
            >
              Add Exam Type
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
            <thead className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white">
              <tr>
                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">
                  Exam Type
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold">
                  Description
                </th>
                <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {examTypeOptions.slice().map((examtype) => (
                <tr key={examtype.id}>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                    {examtype.type_name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                    {examtype.description}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button
                      className="bg-red-500 text-white px-2 py-1 mx-1 rounded-md hover:bg-red-600 transition duration-300"
                      onClick={() => {
                        deleteExamType(examtype.id, 0);
                      }}
                    >
                      Delete
                    </button>
                    <a
                      href="#"
                      onClick={(e) => editExamTypeInfo(e, examtype)}
                      className="bg-indigo-500 text-white px-2 py-1 mx-1 rounded-md hover:bg-indigo-600 transition duration-300"
                    >
                      Edit
                      <span className="sr-only">, {examtype.type_name}</span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <UpdateExamTypeInfo
        selectedExamType={selectedExamType}
        open={openEditView}
        onClose={upsertModalClosed}
      />
    </div>
  );
}

export default ExamType;
