import React from "react";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import Login from "./views/loginPage";
import Register from "./views/Register";
import ProfilePage from "./views/Profile";
import UpdateProfilePage from "./views/updateProfile";
import GetNotes from "./views/GetNotes";
import HomeAuth from "./views/HomeAuth";
import HomeNonAuth from "./views/HomeNonAuth";
import NoteForm from "./components/NoteForm";
import NoteDetail from "./views/NoteDetail";
import Exams from "./views/Exams";
import PatientList from "./views/PatientList";
import Scan from "./views/Scan";
import ExamType from "./views/ExamType";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <AuthProvider>
          {/* <Navbar /> */}
          <Routes>
            {/* <Route exact path='/' element={<PrivateRoute/>}>
              <Route exact path='/' element={<HomeAuth/>}/>
              <Route element={<ProfilePage/>} path="/profile" exact />
              <Route element={<UpdateProfilePage/>} path="/update" exact />
              <Route element={<GetNotes/>} path="/notes" exact />
              <Route element={<NoteForm/>} path="/notes/:id/edit" exact />
              <Route element={<NoteDetail/>} path="/notes/:id/" exact />
              <Route element={<NoteForm/>} path="/notes/new" exact />
            </Route> */}

            <Route exact path="/" element={<PrivateRoute />}>
              <Route element={<PatientList />} path="/patients" />
              <Route element={<Exams />} path="/patients/:patient_id/exams" />
              <Route
                element={<Scan />}
                path="/patients/:patient_id/exams/:exam_id"
              />
            </Route>

            <Route element={<HomeNonAuth />} path="/welcome" exact />
            <Route element={<Login />} path="/login" />
            <Route element={<ExamType />} path="/examtypes" />
            <Route element={<Register />} path="/register" />
          </Routes>
        </AuthProvider>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
